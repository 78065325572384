import { logError } from "repoV2/utils/common/error/error";
import { isBrowser } from "repoV2/utils/common/render/window";
import { GOOGLE_TAG_MANAGER_EVENT_TYPE } from "./GoogleTagManager.constants";
import { ANALYTICS_GTM_EVENTS_MAP } from "../../Analytics.constants";
import { IAnalytics } from "../../Analytics.interfaces";

export const callGtag = (
    eventType: string,
    eventData: IAnalytics.IEventData
) => {
    const gtmEventType = ANALYTICS_GTM_EVENTS_MAP[eventType];

    // e.g, `ANALYTICS_EVENTS.SALE_PAGE_REGISTER_CTA_CLICK` is not tracked for GTM
    // `gtmEventType` will be undefined in that case
    if (!gtmEventType) return;

    const { currency, value, items, transaction_id } = eventData;

    const gtmEventData = {
        currency,
        value,
        items,
        transaction_id,
    };

    if (isBrowser() && typeof window?.gtag === "function") {
        window.gtag(
            GOOGLE_TAG_MANAGER_EVENT_TYPE.EVENT,
            gtmEventType,
            gtmEventData
        );
    } else {
        logError({
            error: "gtag not defined",
            occuredAt: "analytics.ts",
            when: "calling Gtag",
            extraErrorData: {
                location: "callGtag",
                isBrowser: isBrowser(),
                // @ts-ignore
                typeOfGtag: typeof window?.gtag,
                gtmEventType,
                gtmEventData,
            },
        });
    }
};
